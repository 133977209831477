<template>
  <v-app>
    <v-app-bar v-if="$route.meta.top" height="80" app flat class="custom-border" color="#fff" style="z-index: 7">
      <v-avatar tile>
        <v-img contain :aspect-ratio="1.7" :src="`${asset_path}/favicon.png`"/>
      </v-avatar>
      <v-spacer/>
      <title-ellipse :title="$route.name.replace('Mobile User', '')" font="f15"/>
      <v-btn icon small class="mx-3" @click="drawer=!drawer">
        <v-icon size="22" >{{drawer?'mdi-close':'mdi-menu'}}</v-icon>
      </v-btn>
      <avatar :user="user" size="32" />
    </v-app-bar>
    <v-navigation-drawer 
      style="padding-top: 80px;z-index: 5;" 
      absolute
      width="100%" 
      hide-overlay
      v-model="drawer"
    >
      <template v-slot:prepend>
        <v-sheet color="secondary-5 px-5 d-flex align-center justify-space-between">
          <div class="roboto py-2 f14 fw400 secondary-3--text">
            {{`${user.first_name} ${user.last_name}`}}
          </div>
          <v-btn icon @click="logout">
            <v-icon>
              mdi-electric-switch
            </v-icon>
          </v-btn>
        </v-sheet>
      </template>
      <UserNav/>
      <template v-slot:append>
        <v-list class="drawer">
        <v-divider/>

          <v-list-item exact class="px-5 li " 
          :class="$route.name == `Mobile ${user.role.charAt(0).toUpperCase()+ user.role.slice(1)} Account Settings` 
          ? 'li-active' : $route.meta.parent == `Mobile ${user.role.charAt(0).toUpperCase()+ user.role.slice(1)} Account Settings` 
          ? 'li-active' : ''"
          :to="{name: `Mobile ${user.role.charAt(0).toUpperCase()+ user.role.slice(1)} Account Settings`}" 
          v-if="user.role != 'admin'">
            
            <v-list-item-title class="roboto f14 d-flex align-center secondary-1--text">
              <v-icon size="20" color="#b2bec3">mdi-cog-outline</v-icon>
              <div class="ma-2 pt-1 fw500">Account Settings</div>
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item exact class="px-5 li"  v-if="user.role == 'USER'">
            <v-list-item-title class="roboto f14 d-flex align-center secondary-1--text">
              <v-icon size="20" color="#b2bec3">mdi-help-circle-outline</v-icon>
              <div class="ma-2 pt-1 fw500">Help Desk</div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted, reactive, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import UserNav from './UserNav.vue'

export default {
  components: {
    UserNav
  },
  setup() {

    const drawer= ref(null);
    const user= computed(() => store.state.user)
    const asset_path = `${process.env.VUE_APP_ASSET_PATH}`

    onMounted( async() => {
      await store.dispatch('authAction')
    })

    const logout = async() => await store.dispatch('logoutAction')
    
    return { 
      name,
      user,
      drawer,
      logout,
      asset_path
    }
  }
}
</script>
