<template>
  <v-list class="mt-n2 drawer" >
    <section v-for="(item,i) in links" :key="i">
      <v-list-item :to="{name: item.to}"
          exact
          class="px-5 li" 
          :class="$route.name === item.to ? 'li-active' 
          : $route.meta.parent === item.to ? 'li-active' :  ''"
         
      >
        <v-list-item-content>
          <v-list-item-title class="roboto f14 d-flex align-center" 
          :class="$route.name === item.to ? 'primary--text'  
          : $route.meta.parent === item.to ? 'primary--text' 
          : 'secondary-2--text'">
            <v-icon size="20" >{{item.icon}}</v-icon>
            <div class="mx-3 fw500">{{item.name}}</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
    </section>
  </v-list>
</template>

<script>
import {
  ref,
  reactive
} from '@vue/composition-api'

export default {
  setup() {
    const links = ref([
      {
        name: 'Dashboard',
        to: 'Mobile User Dashboard',
        icon: 'mdi-view-dashboard-variant-outline'
      },
      {
        name: 'My Courses',
        to: 'Mobile User My Courses',
        icon: 'mdi-book-open-outline'
      },
      {
        name: 'Calendar',
        to: 'Mobile User Calendar',
        icon: 'mdi-calendar-outline'
      },
      {
        name: 'Inbox',
        to: 'Mobile User Inbox',
        icon: 'mdi-message-outline'
      },
    ])
    
    return {
      links
    }
  }
}
</script>
